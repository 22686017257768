<label class="radio-container">
  <div class="radio">
    <input
      #input
      type="radio"
      [disabled]="disabled"
      [checked]="checked"
      [attr.name]="name"
      [attr.data-checked]="input.checked"
      [attr.data-disabled]="input.disabled"
      (change)="onChange($event.target['checked'])"
    />
    <span class="button"></span>
  </div>

  @if (label || description || tooltip) {
    <div class="content">
      <div class="content__label">
        @if (label) {
          <div class="content__label-text">
            @if (required) {
              <span class="content__label-text--required">*</span>
            }
            {{ label }}
          </div>
        }
        @if (tooltip) {
          <i class="icon icon-info-fill content__label-tooltip"></i>
        }
      </div>
      @if (description) {
        <div class="content__description">{{ description }}</div>
      }
    </div>
  }
</label>
